<template>
    <div class="sidebar-wrapper">
        <ul>
            <li v-for="item in items" :key="item.id">
                <router-link :to="{ name: item.name, query: item.name === 'Profile' ? { isMenu : true} : '' }">
                    {{ item.text }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="sass" scoped>
.sidebar-wrapper
  ul
    li
      border: 1px solid #B4B4B4
      margin: 2px 0
      padding: 5px 10px
      @include font-source('Poppins', 13px, 400, #737373)
      cursor: pointer
      text-align: left
      a
        text-decoration: none
        color: inherit
</style>