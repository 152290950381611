<template>
    <div class="input-wrapper">
        <label for="" v-if="label">{{ label }}</label>
        <input v-model="inputValue" :type="type" @keyup.enter="$emit('onEnter')" @input="onUpdate($event.target.value)">
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            inputValue: this.value
        }
    },
    methods: {
        onUpdate(value) {
            this.$emit('onUpdate', value)
        }
    },
    watch: {
        value() {
            this.inputValue = this.value
        }
    }
}
</script>

<style lang="sass" scoped>
.input-wrapper
   display: flex
   flex-direction: column
   label
     align-self: baseline
     @include font-source('Poppins', 13px, 600, $black1)
   input
     padding: 12px 15px
     &:active, &:focus
        outline: none
</style>