<template>
  <div :class="{'bottom' : bottom}" class="top-section">
    <h1>{{ page }}</h1>
    <p>{{ from }}<span class="arrow">></span><span class="grey-text">{{ page }}</span></p>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    },
    bottom: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.top-section
  padding: 3.5em 0
  border: 1px solid $grey-5
  margin-bottom: 2.5em
  &.bottom
    margin-bottom: 0
  h1
    @include font-source('Poppins', 30px, 600, $black1)
    margin-bottom: 35px
  p, span
    @include font-source('Poppins', 12px, 400, $black1)
  .arrow
    margin: 0 10px
.grey-text, .arrow
  opacity: 0.8
</style>