<template>
    <div class="wrapper">
        <section v-if="List && List.length > 0">
        <div id='doc' class="container">
            <header class="header">
                <div :class="{'cell-0': index === 0}" class="cell" v-for="(item, index) in heading" :key="item.id">
                    {{ item }}
                </div>
                <div class="cell" v-if="icons" />
            </header>
            <div v-for="(item, key) in List" :key="key">
                <div class="row">
                    <div v-for="(obj, index) in Object.keys(item)" :key="obj.id" :class="{'cell-0': index === 0}" class="cell">
                        <a class="anchor" v-if="obj === 'action'" @click="activeBar(item.id, key)">{{ item[obj] }}</a>
                        <span v-else-if="obj === 'published_at'">
                            {{ dateFormatter(item[obj]) }}
                        </span>
                        <span v-else>
                            {{ item[obj] ? item[obj] : 'xxxxxx' }}
                        </span>
                    </div>
                    <div v-if="icons" class="cell">
                        <span>
                            <img src="@/assets/images/pdfIcon.jpg" alt="pdfIcon" @click="generatePDF(key)" />
                            <img src="@/assets/images/printIcon.jpg" alt="printIcon" />
                            <img src="@/assets/images/excelIcon.jpg" alt="excelIcon" />
                            <img src="@/assets/images/emailIcon.jpg" alt="emailIcon" />
                        </span>
                    </div>
                </div>
                <slot v-if="isOpen && openIndex == key" />
            </div>
        </div>
        </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { jsPDF } from "jspdf"
import autoTable from 'jspdf-autotable'
import dateFormatter from '@/mixins/dateFormatter'
export default {
    mixins: [dateFormatter],
    props: {
        heading: {
            type: Array,
            default: () => []
        },
        List: {
            type: Array,
            default: () => []
        },
        icons: {
            type: Boolean,
            default: false
        }
    },
    computed: {
      ...mapState('order', ['orderList'])
    },
    data() {
        return {
            isOpen: false,
            openIndex: null
        }
    },
    methods: {
        activeBar(id, key) {
            this.isOpen = true
            this.openIndex = key
            this.$emit('active', id)
        },
        generatePDF(index) {
            let data = this.List[index]
            data = Object.assign({}, data)
            delete data.action
            let productDetails = []
            const orderDetails = this.orderList.filter(item => item.id === data.id)
            if (orderDetails && orderDetails.length > 0) {
                productDetails = orderDetails[0].order_product.map(({ Price, Quantity, product }) => ({ Price, Quantity, product }))
                productDetails = productDetails.map(({ Price, Quantity, product }) => ({ ['SKU'] : product['SKU'], ['Name']: product['Name'], ['CubeFt']: product['CubeFt'], ['Basic_cost']: product['Price'].toFixed(2), Quantity, ['total_CubeFt']: product['CubeFt'] * Quantity,['Price']: Price * Quantity}))
            }
            const doc = new jsPDF()
            autoTable(doc, {
                body: [
                    data,
                ],
                columns: [
                    { header: 'No.', dataKey: 'id' },
                    { header: 'Order No.', dataKey: 'OrderNumber' },
                    { header: 'PO#', dataKey: 'PONumber' },
                    { header: 'Ship Date', dataKey: 'ShipDate' },
                    { header: 'Total Cube Ft.', dataKey: 'TotalCubFt' },
                    { header: 'Total Price', dataKey: 'SubTotal' }
                ],
                didDrawPage: function (data) {
                    doc.text("Order details", 15, 10)
                }
            })
            if (productDetails && productDetails.length > 0) {
                autoTable(doc, {
                    body: productDetails,
                    columns: [
                        { header: 'SKU', dataKey: 'SKU' },
                        { header: 'Product Name', dataKey: 'Name' },
                        { header: 'Cube Ft.', dataKey: 'CubeFt' },
                        { header: 'Basic Cost', dataKey: 'Basic_cost' },
                        { header: 'Qty.', dataKey: 'Quantity' },
                        { header: 'Total Cube Ft.', dataKey: 'total_CubeFt' },
                        { header: 'Total Price', dataKey: 'Price' }
                    ],
                    styles: { halign: 'center' }
                })
            }
            const name = `order-details-${new Date().getTime()}`
            doc.save(`${name}.pdf`)
        }
    }
}
</script>

<style lang="sass" scoped>
    .wrapper
      padding: 20px 0 100px 0
      section
        .container
          border: 1px solid $grey-2
          border-bottom: 3px solid $grey-2
        .row, .header
          display: flex
        .header
          background: #E9E9E9
          .cell
            @include font-source('Poppins', 13px, 600, $black1)
        .cell
          padding: 5px
          flex: 1
          @include font-source('Poppins', 13px, 400, $black1)
          img
            cursor: pointer
          .anchor
            text-decoration: underline
            color: blue
            cursor: pointer
        .cell-0
          flex: 0.2
    </style>