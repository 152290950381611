import { mapState } from 'vuex'
export default {
    data () {
        return {
          userSideBar: [
              {
                id: 1,
                text: 'Update Your Profile',
                name: 'Profile'
              },
              {
                id: 2,
                text: 'Your Sale Agent',
                name: 'SalesRep'
              },
              {
                id: 3,
                text: 'Order History',
                name: 'SaleHistory'
              },
              {
                id: 4,
                text: 'Your Credit Limit ',
                name: 'CreditLimit'
              },
              {
                id: 5,
                text: 'Change Password',
                name: 'Profile'
              }
          ],
          saleSideBar: [
            {
              id: 1,
              text: 'Update Your Profile',
              name: 'Profile'
            },
            {
              id: 2,
              text: 'Customer List',
              name: 'CustomerList'
            },
            {
              id: 3,
              text: 'Change Password',
              name: 'Profile'
            }
        ]
        }
    },
    computed: {
      ...mapState('auth', [ 'userType', 'salesRep']),
      sideBarData() {
        let list = this.userSideBar
        if (this.userType === this.salesRep) {
          list = this.saleSideBar
        }
        return list
      }
    }
  }
  